<template>
  <div class="tw-flex tw-flex-row tw-bg-black tw-text-white tw-h-[243.73px]">
    <div class="tw-basis-3/5">
      <div v-if="loading" class="tw-h-[171px]">
        Loading...
      </div>

      <div v-if="!loading" class="tw-p-7">
        <div class="tw-text-3xl tw-font-bold tw-tracking-wide">
          Trends in {{ selectedGroupName ? selectedGroupName.label : '' }}
        </div>
        <ul class="tw-list-disc tw-pl-4 tw-mt-4">
          <li v-for="(event, index) in events" :key="index" class="tw-mb-3">
            {{ event.abstract }}
          </li>
        </ul>
      </div>
    </div>
    <div v-if="!loading && selectedGroupName" class="tw-basis-2/5 tw-bg-cover" :style="{ background: `url(/static/images/trends/${selectedGroupName.value}.jpg)` }" />
  </div>
</template>

<script>
export default {
  props: {
    loading: {
      type: Boolean,
      required: true
    },

    selectedGroup: {
      type: String,
      required: true
    },

    groups: {
      type: Array,
      required: true
    },

    events: {
      type: Array,
      required: true
    }
  },

  computed: {
    selectedGroupName () {
      return this.groups.find(group => group.value === this.selectedGroup)
    }
  }
}
</script>
