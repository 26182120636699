<template>
  <layout name="NoColumns">
    <div class="tw-py-4 tw-px-4 tw-flex tw-flex-col tw-gap-8 tw-bg-white">
      <div class="tw-w-[30%]">
        <BasicSelect
          v-model="selectedGroup"
          :disabled="loading"
          :values="groups"
          key-prop="value"
          label-prop="label"
          value-prop="value"
        />
      </div>

      <Events :loading="loading" :events="top10.events" />

      <Topics
        :loading="loading"
        :selected-group="selectedGroup"
        :groups="groups"
        :events="top3.events"
      />

      <EntitiesTable
        :loading="loading"
        :entities="entities"
        :topics="top10.topics"
        :sorting="sorting"
        @sorting:update="handleSortingUpdate"
      />
    </div>
  </layout>
</template>

<script>
import { mapGetters } from 'vuex'
import BasicSelect from '@hypefactors/shared/js/components/core/BasicSelect.vue'

import Events from '@/pages/trends/partials/Events.vue'
import Topics from '@/pages/trends/partials/Topics.vue'
import EntitiesTable from '@/pages/trends/partials/EntitiesTable.vue'

export default {
  components: {
    BasicSelect,
    Events,
    Topics,
    EntitiesTable
  },

  data () {
    return {
      loading: false,
      groups: [],
      entities: [],
      top3: {
        events: [],
        topics: []
      },
      top10: {
        events: [],
        topics: []
      },
      selectedGroup: 'entertainers',
      sorting: {
        column: 'latest',
        direction: 'desc'
      }
    }
  },

  computed: {
    ...mapGetters(['activeBrand'])
  },

  watch: {
    async selectedGroup () {
      await this.fetchEntities()
    },

    activeBrandId: {
      handler: 'loadData',
      immediate: true
    }
  },

  methods: {
    loadData () {
      this.fetchGroups()

      this.fetchEntities()
    },

    async fetchGroups () {
      await this.$api.get('/trends/groups')
        .then(response => {
          this.groups = response.data
        })
    },

    async fetchEntities () {
      this.loading = true

      await this.$api.get(`/trends/entities?sortColumn=${this.sorting.column}&sortDirection=${this.sorting.direction}&group=${this.selectedGroup}`)
        .then(response => {
          this.entities = response.data.entities
          this.top3 = response.data.top_3
          this.top10 = response.data.top_10
          this.loading = false
        })
    },

    handleSortingUpdate (sorting) {
      this.sorting.column = sorting.column
      this.sorting.direction = sorting.direction

      this.fetchEntities()
    }
  }
}
</script>

<style lang="scss">
@import './trends.css';
</style>
